
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Apollo, Notify } from "@/core/services";
import DiscountAction from "./partials/DiscountAction.vue";
import DiscountCondition from "./partials/DiscountCondition.vue";
import DiscountGeneralInfo from "./partials/GeneralInfo.vue";
import { CREATE_DISCOUNTS } from "../graphql/Mutations";
import { GET_DISCOUNTS } from "../graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Add discount",
	components: {
		DiscountAction,
		DiscountCondition,
		DiscountGeneralInfo,
		InnerLoader,
	},

	setup() {
		const i18n = useI18n();
		const modal: any = ref();
		const loading = ref(false);
		const activeTab = ref("first");
		const generalInfo = ref<null | HTMLFormElement>(null);
		const actionForm = ref<null | HTMLFormElement>(null);
		const conditionForm = ref<null | HTMLFormElement>(null);
		const emitter: any = inject("emitter");
		const formData = ref({}) as Record<any, any>;
		const stepValidate = ref({
			generalForm: false,
			conditionForm: false,
			actionForm: false,
		}) as Record<any, any>;

		const stepLogValidate = (action: any = null, validate: any = null) => {
			return action == null ? stepValidate.value : validate ? (stepValidate.value[action] = true) : (stepValidate.value[action] = false);
		};

		const dataHandler = (action: any, data: Record<any, any>, validate: boolean) => {
			formData.value[action] = data;
			stepLogValidate(action, validate);
		};

		const submitHandler = async () => {
			actionForm.value?.validateForm();
			conditionForm.value?.validateForm();
			generalInfo.value?.validateForm();
			const validate = stepLogValidate();
			if (validate.generalForm == true && validate.actionForm == true && validate.conditionForm == true) {
				if (formData.value?.conditionForm.date == null || formData.value?.conditionForm.date.length == 0) {
					Notify.error(i18n.t("message.SELECT_DATE"));
					return;
				}
				const sendObject = JSON.stringify(formData.value);
				loading.value = true;
				await Apollo.mutate({
					mutation: CREATE_DISCOUNTS,
					variables: {
						input: sendObject,
					},
					update: (store, { data: { create_discount } }) => {
						const read_data = store.readQuery({
							query: GET_DISCOUNTS,
							variables: {
								page: 1,
								limit: 10,
							},
						}) as Record<any, any>;
						store.writeQuery({
							query: GET_DISCOUNTS,
							variables: {
								page: 1,
								limit: 10,
							},
							data: {
								discounts: {
									...read_data.discounts,
									data: [create_discount, ...read_data.discounts.data],
								},
							},
						});
						modal.value.hide();
						loading.value = false;
						Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
					},
				}).catch(() => {
					loading.value = false;
				});
			} else {
				Notify.error(i18n.t("message.FILL_ALL_MISSING_FIELDS"));
			}
		};

		// Emitter for open modal
		emitter.on("addDiscountAction", () => {
			actionForm.value?.resetForm();
			conditionForm.value?.resetForm();
			generalInfo.value?.resetForm();
			modal.value = new Modal(document.getElementById("add_discount_model"));
			modal.value.show();
		});

		return {
			activeTab,
			actionForm,
			conditionForm,
			generalInfo,
			loading,
			submitHandler,
			dataHandler,
		};
	},
});
