
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Apollo, Notify } from "@/core/services";
import DiscountAction from "./partials/DiscountAction.vue";
import DiscountCondition from "./partials/DiscountCondition.vue";
import DiscountGeneralInfo from "./partials/GeneralInfo.vue";
import { UPDATE_DISCOUNTS } from "../graphql/Mutations";
import { GET_DISCOUNTS } from "../graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "Add discount",
	components: {
		DiscountAction,
		DiscountCondition,
		DiscountGeneralInfo,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const activeTab = ref("first");
		const generalInfo = ref<null | HTMLFormElement>(null);
		const actionForm = ref<null | HTMLFormElement>(null);
		const conditionForm = ref<null | HTMLFormElement>(null);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const formData = ref({}) as Record<any, any>;
		const loader = ref(false);
		const assignValues = ref({});
		const stepValidate = ref({ generalForm: false, conditionForm: false, actionForm: false }) as Record<any, any>;

		// Emitter To Open Model
		emitter.on("editDiscountAction", (data: object) => {
			activeTab.value = "first";
			actionForm.value?.editDiscountValue(data);
			conditionForm.value?.editDiscountValue(data);
			generalInfo.value?.editDiscountValue(data);
			modal.value = new Modal(document.getElementById("edit_discount_model")) as HTMLElement;
			modal.value.show();
		});

		const stepLogValidate = (action: any = null, validate: any = null) => {
			return action == null ? stepValidate.value : validate ? (stepValidate.value[action] = true) : (stepValidate.value[action] = false);
		};

		const dataHandler = (action: any, data: Record<any, any>, validate: boolean) => {
			formData.value[action] = data;
			stepLogValidate(action, validate);
		};

		const submitHandler = async () => {
			actionForm.value?.validateForm();
			conditionForm.value?.validateForm();
			generalInfo.value?.validateForm();
			const validate = stepLogValidate();
			if (validate.generalForm == true && validate.actionForm == true && validate.conditionForm == true) {
				if (formData.value?.conditionForm.date == null || formData.value?.conditionForm.date.length == 0) {
					Notify.error(i18n.t("message.SELECT_DATE"));
					return;
				}
				const sendObject = JSON.stringify(formData.value);
				loader.value = true;
				await Apollo.mutate({
					mutation: UPDATE_DISCOUNTS,
					variables: {
						input: sendObject,
					},
					update: (store, { data: { create_discount } }) => {
						modal.value.hide();
						loader.value = false;
						Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
					},
				}).catch(() => {
					loader.value = false;
					// loading.value = false
				});
			} else {
				Notify.error(i18n.t("message.FILL_ALL_MISSING_FIELDS"));
			}
		};

		return {
			activeTab,
			actionForm,
			conditionForm,
			generalInfo,
			assignValues,
			loader,
			submitHandler,
			dataHandler,
		};
	},
});
