import { gql } from "graphql-tag";

export const DISCOUNT_FIELDS = gql`
  fragment DiscountFields on Discount {
    id
    name
    description
    code
    path
    status
    discount_action {
      product_id
      discount_type
      discount_value
    }
    cart_discount_rule {
      id
      date_to
      date_from
      total_available
      available_per_use
      available_for
      discount_rule_to_customers {
        customer_id
        customers {
          id
          user_id
          user {
            name
          }
        }
      }
      discount_rule_to_sites {
        site_id
        sites {
          id
          name
        }
      }
      discount_rule_to_customer_groups {
        customer_group_id 
        customer_groups {
          id
          name
        }
      }
      discount_rule_to_countries {
        country_id 
        countries {
          id
          name
        }
      }
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on DiscountPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export default { DISCOUNT_FIELDS, PAGINATION }