
import { defineComponent, ref, inject } from 'vue'
import {Input, Select} from "@/components/input-elements";
import { Apollo } from "@/core/services";
import { GET_PRODUCT_FOR_DISCOUNT_LIST } from '../../graphql/Queries'
import { useStore } from "vuex";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
    components:{
        Input,
        Select,
        InnerLoader
    },
    setup(props, context) {
        const products = ref([]) as Record< any, any>
        const store = useStore()
		const loading = ref(false);
        const locale = ref("da");
        const actionFromRef = ref <null |HTMLFormElement>(null)
        const emitter: any = inject("emitter");

        const LoadProducts = () => {
            loading.value = true
            Apollo.watchQuery({
                query: GET_PRODUCT_FOR_DISCOUNT_LIST,
                fetchPolicy: "network-only",
                nextFetchPolicy: "cache-only",
                errorPolicy: "all",
            }).subscribe(({data, errors}) => {
                if(errors) {
                    loading.value = false
                }
                products.value = []
                data.products.data.forEach(element => {
                    products.value.push(
                        {
                            label:JSON.parse(element.description.name)[`${locale.value}`].name,
                            value:element.id
                        }
                    )
                });
                loading.value = false
            });
        }

        const actionForm = ref(
            {
                discount_type:'',
                discount_amount:'',
                product_id:''
            }
        )

        const discount_options = ref([
            {
                label:'message.AMOUNT',
                value:'AMOUNT'
            },
            {
                label:'message.PERCENTAGE',
                value:'PERCENT'
            }
        ]); 

        const changeDiscountTypeHandler = async (data) => {
            if(data == 'PRODUCT') {
                await LoadProducts()
            }
        }

        const resetForm = () => {
            emitter.emit("clearInput");
            actionFromRef.value?.resetFields();

        };

        const editDiscountValue = async (data: any) => {
           resetForm()
           if(data.discount_action?.discount_type == 'PRODUCT') 
           {
               await LoadProducts()
           }
            actionForm.value.discount_type = data.discount_action?.discount_type
            actionForm.value.discount_amount = data.discount_action?.discount_value
            actionForm.value.product_id = data.discount_action?.product_id
        }

        const validateForm = () => {
            actionFromRef.value?.validate( (valid) => {
                if(valid) {
                    context.emit('store', 'actionForm', actionForm.value, true)
                    return valid
                } else {
                    context.emit('store', 'actionForm', actionForm.value, false)
                    return valid
                }
            })
        }

        return {
            products,
            discount_options,
            actionForm,
            changeDiscountTypeHandler,
            validateForm,
            actionFromRef,
            resetForm,
            editDiscountValue,
            loading
        }
    },
})
