import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_InnerLoader, {
      loading: _ctx.loading,
      innerLoading: true
    }, null, 8, ["loading"]),
    _createVNode(_component_el_form, {
      model: _ctx.actionForm,
      ref: "actionFromRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Select, {
          name: "discount_type",
          target: "discount_type",
          label: _ctx.$t('message.DISCOUNT_TYPE'),
          clearable: true,
          callEmit: true,
          required: true,
          discount_type: _ctx.actionForm.discount_type,
          "onUpdate:discount_type": _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionForm.discount_type = $event)),
          selectData: _ctx.discount_options,
          value: _ctx.actionForm.discount_type,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeDiscountTypeHandler(_ctx.actionForm.discount_type)))
        }, null, 8, ["label", "discount_type", "selectData", "value"]),
        (_ctx.actionForm.discount_type == 'AMOUNT' || _ctx.actionForm.discount_type == 'PERCENT')
          ? (_openBlock(), _createBlock(_component_Input, {
              key: 0,
              type: "number",
              name: "discount_amount",
              label: _ctx.actionForm.discount_type == 'AMOUNT' ? 'Amount' : 'Percentage',
              discount_amount: _ctx.actionForm.discount_amount,
              "onUpdate:discount_amount": _cache[3] || (_cache[3] = ($event: any) => (_ctx.actionForm.discount_amount = $event)),
              target: "discount_amount",
              value: _ctx.actionForm.discount_amount,
              required: true
            }, null, 8, ["label", "discount_amount", "value"]))
          : _createCommentVNode("", true),
        (_ctx.actionForm.discount_type == 'PRODUCT')
          ? (_openBlock(), _createBlock(_component_Select, {
              key: 1,
              name: "product_id",
              target: "product_id",
              label: 'Products',
              clearable: true,
              callEmit: true,
              required: true,
              product_id: _ctx.actionForm.product_id,
              "onUpdate:product_id": _cache[4] || (_cache[4] = ($event: any) => (_ctx.actionForm.product_id = $event)),
              selectData: _ctx.products,
              value: _ctx.actionForm.product_id,
              filterable: true
            }, null, 8, ["product_id", "selectData", "value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}