
import { gql } from "graphql-tag";
import { DISCOUNT_FIELDS } from "../graphql/Fragments";

export const CREATE_DISCOUNTS = gql`
  mutation CreateDiscount($input: String!) {
    create_discount(discount: $input){
      ...DiscountFields
    }
  }
  ${DISCOUNT_FIELDS}
`;

export const UPDATE_DISCOUNTS = gql`
  mutation UpdateDiscount($input: String!) {
    update_discount(discount: $input){
      ...DiscountFields
    }
  }
  ${DISCOUNT_FIELDS}
`;

export const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($id: Int!) {
    delete_discount(id: $id)
  }
`;

export default { CREATE_DISCOUNTS , UPDATE_DISCOUNTS, DELETE_DISCOUNT}