
import { defineComponent, ref, inject } from 'vue'
import { Input, Select } from "@/components/input-elements";
import { useStore } from "vuex";
import { GET_CUSTOMER_FOR_DISCOUNT_LIST, GET_CUSTOMER_GROUP_FOR_DISCOUNT_LIST } from '../../graphql/Queries'
import { Apollo, Notify } from "@/core/services";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
    components:{
       Input,
       Select,
       InnerLoader
    },
    setup(props, context) {
        const store = useStore()
        const label = ref('')
        const conditionFromRef = ref <null |HTMLFormElement>(null)
        const available_options_for = ref([]) as Record <any, any>
		const loading = ref(false);
        const emitter: any = inject("emitter");

        const conditionForm = ref(
            {
                id:null,
                date:[],
                available_per_use:'',
                total_available:'',
                available_for:'',
                for_data:[] as Record<any, any>
            }
        ) as any

        const available_options = ref([
            {
                label:'message.CUSTOMER',
                value:'SINGLE_CUSTOMER'
            },
            {
                label:'message.CUSTOMER_GROUPS',
                value:'CUSTOMER_GROUP'
            },
            {
                label:'message.SITES',
                value:'SITE'
            },
            {
                label:'message.COUNTRY',
                value:'COUNTRY'
            }
        ]);
        
        const disabledDate = (time) => {
            const date = new Date();
            return time.getTime() < date.setDate(date.getDate() - 1);
        }

        const resetForm = () => {
            emitter.emit("clearInput");
            conditionForm.value.date = []
            conditionFromRef.value?.resetFields();

        };

        const countries = () => {
           loading.value = true
           const countries_data = Apollo.readQuery({
				query: GET_COUNTRIES,
			});
            available_options_for.value = []
			available_options_for.value = countries_data.countries.map(
				(single) => {
					const country = {};
					country["label"] = single.name;
					country["value"] = single.id;
					return country;
				}
			);
            loading.value = false

        }  

        const sites = () => {
            loading.value = true
            const response = store.getters.getAllSites.data.sites
            available_options_for.value = []
            response.forEach(ele => {
                available_options_for.value.push(
                    {
                        label:ele.name,
                        value:ele.id
                    }
                )
            })

            loading.value = false

        }

        const loadData = (query_name, name, dataObject: any = null) => {
            loading.value = true
            Apollo.watchQuery({
                query: query_name,
                fetchPolicy: "network-only",
                nextFetchPolicy: "cache-only",
                errorPolicy: "all",
            }).subscribe(({data, errors}) => {
                if(errors) {
                    loading.value = false
                }
                available_options_for.value = []
                const response = data[name].data

                if(name == 'customers') {
                    response.forEach(element => {
                        available_options_for.value.push(
                            {
                                label:element.user.name,
                                value: element.id
                            }
                        )
                    });
                    loading.value = false
                    return 
                }

                available_options_for.value = []
                response.forEach(ele => {
                    available_options_for.value.push(
                        {
                            label:ele.name,
                            value:ele.id
                        }
                    )
                })

                loading.value = false
            });
        }

        const changeHandler = (data, dataObject:any = null) => {
            conditionForm.value.for_data = []
            switch(data){
                case 'SINGLE_CUSTOMER':
                    label.value = 'Customers'
                    loadData(GET_CUSTOMER_FOR_DISCOUNT_LIST,'customers')
                    break;
                case 'CUSTOMER_GROUP':
                    label.value = 'Customer Groups'
                    loadData(GET_CUSTOMER_GROUP_FOR_DISCOUNT_LIST, 'customer_groups')
                    break;
                case 'SITE':
                    label.value = 'Sites'
                    sites()
                    break;
                case 'COUNTRY':
                    label.value = 'Countries'
                    countries()
                    break;
                default:
                    available_options_for.value = []
                    conditionForm.value.for_data = []
            }
        }

        const editDiscountValue = async (data: any) => {
            loading.value = true
            resetForm()
            await changeHandler(data.cart_discount_rule?.available_for)

            conditionForm.value.id = data.cart_discount_rule?.id
            conditionForm.value.available_for = data.cart_discount_rule?.available_for
            conditionForm.value.total_available= data.cart_discount_rule?.total_available
            conditionForm.value.available_per_use = data.cart_discount_rule?.available_per_use
            conditionForm.value.date.push(data.cart_discount_rule?.date_from,data.cart_discount_rule?.date_to) 

            if(data.cart_discount_rule.discount_rule_to_customers.length > 0) {
                data.cart_discount_rule?.discount_rule_to_customers.forEach(ele => {
                     conditionForm.value.for_data.push(ele.customers[0].id )
                })

            } else if(data.cart_discount_rule?.discount_rule_to_countries.length > 0) {
                data.cart_discount_rule?.discount_rule_to_countries.forEach(ele => {
                     conditionForm.value.for_data.push(ele.countries[0].id)
                })
            } else if(data.cart_discount_rule?.discount_rule_to_customer_groups.length > 0) {
                data.cart_discount_rule?.discount_rule_to_customer_groups.forEach(ele => {
                     conditionForm.value.for_data.push(ele.customer_groups[0].id)
                })
            } else if(data.cart_discount_rule.discount_rule_to_sites.length > 0) {
                data.cart_discount_rule?.discount_rule_to_sites.forEach(ele => {
                    conditionForm.value.for_data.push(ele.site_id)
                })
            }
        }

        const validateForm = () => {
            conditionFromRef.value?.validate( (valid) => {
                if(valid) {
                    context.emit('store', 'conditionForm', conditionForm.value, valid)
                    return valid
                }else {
                    context.emit('store', 'conditionForm', conditionForm.value, valid)
                    return valid
                }
            })
        }

        return {
            available_options,
            disabledDate,
            conditionForm,
            changeHandler,
            label,
            conditionFromRef,
            validateForm,
            available_options_for,
            resetForm,
            editDiscountValue,
            loading
        }
    },
})
