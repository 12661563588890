import {gql} from "graphql-tag";
import {PAGINATION, DISCOUNT_FIELDS} from "@/modules/system/discounts/graphql/Fragments";

export const GET_DISCOUNTS = gql`
    query GetDiscounts($page: Int, $limit: Int) {
        discounts(limit:$limit, page:$page){
            ...Pagination
            data {
                ...DiscountFields
            }
        }
    }
    ${DISCOUNT_FIELDS}
    ${PAGINATION},
`;

export const SEARCH_DISCOUNTS = gql`
    query SearchDiscounts($page: Int, $limit: Int, $filter: String, $search_key:String, $type:String , $date_from:String , $date_to:String) {
        search_discounts(limit:$limit, page:$page, filter:$filter, search_key:$search_key, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...DiscountFields
            }
        }
    }
    ${DISCOUNT_FIELDS}
    ${PAGINATION}
`;

//
export const GET_PRODUCT_FOR_DISCOUNT_LIST = gql`
    query GetProductsForDiscountList($page: Int, $limit: Int) {
        products(limit:$limit, page:$page){
            data {
                id
                description {
                    name
                } 
            }
        }
    }
`;

//
export const GET_CUSTOMER_FOR_DISCOUNT_LIST = gql`
    query GetCustomerForDiscountList($page: Int, $limit: Int) {
        customers(limit:$limit, page:$page){
            data {
                id
                user {
                    name
                }
            }
        }
    }
`;

//
export const GET_CUSTOMER_GROUP_FOR_DISCOUNT_LIST = gql`
    query GetCustomerGroupForDiscountList($page: Int, $limit: Int) {
        customer_groups(limit:$limit, page:$page){
            data {
                id
                name
            }
        }
    }
`;

//
export default { GET_CUSTOMER_FOR_DISCOUNT_LIST, GET_CUSTOMER_GROUP_FOR_DISCOUNT_LIST, GET_PRODUCT_FOR_DISCOUNT_LIST, SEARCH_DISCOUNTS, GET_DISCOUNTS }